.text-center {
  text-align: center;
}

.munk-logo {
  height: 40vmin;
  pointer-events: none;
  background-clip: text;
  fill: url("./triangle-prism.gif");
  -webkit-background-clip: text;
  color: transparent;
  font-family: 'Tourney', cursive;
  font-size: calc(13px + 20vmin);
  background-size: contain;
  -webkit-text-fill-color: transparent;
  // -webkit-animation: stripes 2s linear infinite;
  // animation: stripes 2s linear infinite;
}

.munk-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: darkblue;
}

.App-link {
  color: #61dafb;
}

// @-webkit-keyframes stripes {
//   100% {
//     background-position: 0 -50px;
//   }
// }

// @keyframes stripes {
//   100% {
//     background-position: 0 -50px;
//   }
// }