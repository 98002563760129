.main {
  height: 30vh;
  color: black;

  font-family: 'PT Serif', cursive;
  font-size: 24px;
  text-align: center;

  .header {
    color: black;
  }

  .profile-photo {
    height: 33%;
    width: auto;
    border-radius: 100%;
  }

  .profile {
    display: flex;
    flex-direction: row;
    width: 50%;
  }

  .text-animation {
    height: 64px;
  }

  @media (max-width: 768px) {
    font-size: 20px;    
  }
}

.cta-button {
  background-image: url("./triangle-prism.gif");
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-family: 'PT Sans';
  font-size: 14px;
  font-weight: bold;
  margin-top: 26px;
  opacity: 0.2;
  padding: 13px 26px;
  text-transform: uppercase;
  transition: opacity 0.5s;
  border-bottom: none;
  text-decoration: none;
    
  &.active {
    opacity: 0.69;
  }
  
  &:hover {
    opacity: 1;
    padding: 13px 52px;
    transition: opacity 0.25s, padding 0.25s;
    border-bottom: none;
  }  
  
}