.logo {
  font-family: 'Tourney', cursive;
  font-size: calc(13px + 20vmin);
  color: black;
  cursor: pointer;

  &.animation {
    background-clip: text;
    background-image: url("./triangle-prism.gif");
    -webkit-background-clip: text;
    color: transparent;
    background-size: cover;
    -webkit-text-fill-color: transparent;
    &:hover {
      background-image: url('./cat-unicorn.gif');
      background-size: contain;
    }
  }
}

.header {
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 30vh;
  }
}
