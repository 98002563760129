.footer {
  height: 20vh;
  color: black;
  font-family: 'Helvetica', cursive;
  font-size: calc(14px);
  display: flex;
  text-align: center;

  @media (max-width: 768px) {
    height: 30vh;
    height: auto;
    flex-direction: column;
  }

  section {
    flex: 1 1 auto;
    padding: 13px;
  }
}

.image-link {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 4px;
  }
}

.link {
  color: black;
  vertical-align: middle;
  transition: color 0.5s;
  text-decoration: none;
}

.link:hover {
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
  -webkit-text-fill-color: transparent;
  background-image: url("./triangle-prism.gif");
  background-size: cover;
  color: transparent;
}